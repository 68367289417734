<template>
    <div>
        <div class="custom-search d-flex justify-content-end mb-1">
            <div class="float-left mr-auto d-flex align-items-center">
                <switches
                        v-model="reservationEnabled"
                        text-enabled="Nyitva"
                        text-disabled="Zárva"
                        type-bold="true"
                        theme="custom"
                        color="primary"
                        class="mr-2"
                        :emit-on-mount="false"
                        @input="setReservableStatus"
                />
                <b-button v-if="!reservationEnabled"
                          variant="primary"
                          @click="newEntity"
                >
                    <span class="text-nowrap">Tárgyév nyitása</span>
                </b-button>
            </div>
        </div>

        <b-alert
                variant="warning"
                :show="reservationEnabled"
                class="mb-2"
        >
            <div class="alert-body">
                <feather-icon
                        icon="AlertTriangleIcon"
                        class="mr-50"
                />
                Tárgyév nyitására csak akkor nyílik lehetőség, ha a foglalás zárva van.<br>
            </div>
        </b-alert>

        <!-- table -->
        <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
                :sort-options="{
          enabled: true,
          initialSortBy: sortBy
        }"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
        >
            <div slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">
                    {{ $t('table.list.empty') }}
                </div>
            </div>

            <!-- pagination -->
            <template
                    slot="pagination-bottom"
                    slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                        <b-form-select
                                v-model="pageLength"
                                :options="pages"
                                class="mx-1"
                                @change="handlePageChange"
                        />
                        <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                    </div>
                    <div>
                        <b-pagination
                                :value="1"
                                :total-rows="dataFromServer.total"
                                :per-page="dataFromServer.per_page"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @change="handleChangePage"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BAlert,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import Switches from "vue-switches";

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BAlert,
        Switches,
    },
    data() {
        return {
            role: 'reservation',
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: 'Tárgyév',
                    field: 'year',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Tárgyév szerinti szűrés',
                    },
                },
            ],
            rows: [],
            page: 1,
            sortBy: {
                field: 'year',
                type: 'desc',
            },
            reservationEnabled: false,
            dataFromServer: [],
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        this.initEntities()
    },
    methods: {
        initEntities() {
            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: this.columnFilters,
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchReferenceYears', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            }).finally(() => {
                this.$store.dispatch('fetchReservationDashboard').then(response => {
                    this.reservationEnabled = !Boolean(response.data.closed);
                })
            })
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        newEntity() {
            this.$router.push({name: 'add-reservation-reference-year'})
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initEntities()
        },
        onColumnFilter(params) {
            this.page = 1
            this.columnFilters = params.columnFilters
            this.initEntities()
        },
        setReservableStatus() {
            this.$store.dispatch('setReservableStatus', {
                closed: !this.reservationEnabled
            }).then(() => {
                this.initEntities()
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss">
.vue-switcher-theme--custom {
  &.vue-switcher-color--primary {
    div {
      background-color: lighten(#29c770, 10%);

      &:after {
        background-color: darken(#29c770, 5%);
      }
    }

    &.vue-switcher--unchecked {
      div {
        background-color: lighten(#ea5556, 30%);

        &:after {
          background-color: lighten(#ea5556, 10%);
        }
      }
    }
  }
}
</style>
